<template>
<div class="scope">
    <div class="center m-3" v-if="loading">
        <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
    </div>
    <div v-else>
        <a-alert v-if="alert_success != ''"
            :message="alert_success"
            description=""
            type="success"
            show-icon
        />
        <a-alert v-if="alert_danger != ''"
            :message="alert_danger"
            description=""
            type="error"
            show-icon
        />
        <div v-if="data_personal">
            <a-list item-layout="horizontal">
                <a-list-item>
                    <a-list-item-meta
                        :description="firstname+' '+lastname"
                    >
                        <span slot="title">ชื่อ - สกุล</span>
                    </a-list-item-meta>
                </a-list-item>
                <a-list-item>
                    <a-list-item-meta
                        :description="tax_address"
                    >
                        <span slot="title">เลขประจำตัวผู้เสียภาษี</span>
                    </a-list-item-meta>
                </a-list-item>
                <a-list-item>
                    <a-list-item-meta
                        :description="full_addr"
                    >
                        <span slot="title">ที่อยู่</span>
                    </a-list-item-meta>
                </a-list-item>
            </a-list>
            <span class="yellowBTN" @click="edit_data()">แก้ไข</span>
        </div>
        <div v-else>
            <a-form :form="form" @submit="set_data()">
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="11">
                        <a-form-item label="ชื่อ">
                            <a-input v-model="firstname"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="11">
                        <a-form-item label="นามสกุล">
                            <a-input v-model="lastname"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="23">
                        <a-form-item label="เลขประจำตัวผู้เสียภาษี">
                            <a-input-number v-model="tax_address"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="2">
                        <a-form-item label="บ้านเลขที่">
                            <a-input v-model="house_number"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="1">
                        <a-form-item label="หมู่">
                            <a-input v-model="mu"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-item label="อาคาร/หมู่บ้าน">
                            <a-input v-model="tower_village"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="ซอย">
                            <a-input v-model="alley"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="ถนน">
                            <a-input v-model="road"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="23">
                        <addressinput-subdistrict v-model="subdistrict"/> 
                        <addressinput-district v-model="district" /> 
                        <addressinput-province v-model="province" /> 
                        <addressinput-zipcode v-model="zipcode" /> 
                        <a-form-item>
                            <a-button class="yellowBTN" @click="set_data()">
                                <b-spinner v-if="saving" variant="light" type="grow" label="Spinning"></b-spinner>
                                <span v-else>
                                    บันทึก 
                                </span>
                            </a-button>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import router from './../../../router/index'
export default {
    data() {
        return {
            loading:1,
            data_personal: 0,
            house_number: '',
            mu:'',
            tower_village:'-',
            alley:'-',
            road:'-',
            subdistrict: '', 
            district: '', 
            province: '', 
            zipcode: '',
            full_addr: '',
            firstname: '',
            lastname: '',
            tax_address: '',
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            alert_success: '',
            alert_danger: '',
            saving: 0
        }
    },
    mounted(){
        this.check_data()
    },
    methods: {
        logout() {
            let keysToRemove = ["UserInfo", "LoginStatus"];
            if (!localStorage.getItem("AutoLogin")) {
                keysToRemove.push("Username")
                keysToRemove.push("Password")
            }
            if(localStorage.getItem("device_token")){
                keysToRemove.push("device_token")
            }
            keysToRemove.forEach(k => localStorage.removeItem(k))
            router.push("/");
            location.reload()
        },
        async check_data(){
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/profile&submodule=payment_personal_data", formData)
                    .then(res => {
                        if(res.data.status == "200"){
                            this.data_personal = 1
                            this.firstname = res.data.member_first_name;
                            this.lastname = res.data.member_last_name;
                            this.tax_address = res.data.member_tax_addr;
                            this.full_addr = res.data.member_addr;
                        }
                        else if(res.data.status == "404" && res.data.description == "Invalid credential"){
                            this.logout();
                        }
                        this.loading = 0;
                    })
                    .catch(err => {
                        console.error("error : " + err);
                    });
            } catch (err) {
                console.error("error : " + err);
            }
        },
        async set_data(){
            if(this.firstname == '' || this.lastname == '' || this.tax_address == '' || this.subdistrict == '' || this.district == '' || this.province == '' || this.zipcode == ''){
                this.alert_danger = 'กรุณากรอกข้อมูลทุกช่องให้ครบ';
            }
            else{
                this.saving = 1;
                const formData = new FormData();
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                formData.append('firstname', this.firstname);
                formData.append('lastname', this.lastname);
                formData.append('tax_address', this.tax_address);
                var address = this.house_number+" หมู่:"+this.mu+" ซอย:"+this.alley+" ถนน:"+this.road+" ตำบล:"+this.subdistrict+" อำเภอ:"+this.district+" จังหวัด:"+this.province+" "+this.zipcode;
                formData.append('address', address);
                
                try {
                    await axios
                        .post("https://api2.novelrealm.com/module/profile&submodule=set_payment_personal_data", formData)
                        .then(res => {
                            this.saving = 0;
                            if(res.data.status == "200"){
                                this.alert_danger = '';
                                this.alert_success = 'บันทึกข้อมูลสำเร็จ';
                                this.data_personal = 1
                            }
                            else if(res.data.status == "500" && res.data.description == "internal_error"){
                                this.alert_success = '';
                                this.alert_danger = 'เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่';
                            }
                            else if(res.data.status == "404" && res.data.description == "Invalid credential"){
                                this.logout();
                            }
                        })
                        .catch(err => {
                            console.error("error : " + err);
                        });
                } catch (err) {
                    console.error("error : " + err);
                }
            }
        },
        edit_data(){
            this.loading = 1;
            this.data_personal = 0;
            this.loading = 0;
        }
    }
}
</script>

<style scoped>
.yellowBTN{
    color: white;
    background-color: #F5D087;
    border-radius: 6px;
    padding: 3px 25px 3px 25px;
    font-size: 16px;
    border:0px
}
.yellowBTN:hover {
    background-color: #FAC762;
    cursor: pointer;
    font-weight: bold;
}
.th-address-label{
    width:100%;
}
.ant-input-number{
    width: 100%;
}
.th-address .label-text{
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
}
.th-address{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    margin-bottom: 24px;
    vertical-align: top;
}
.center{
    text-align: center;
}
</style>

<style>
input.th-address-input{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
</style>