import { render, staticRenderFns } from "./MyBookBuy.vue?vue&type=template&id=0babdcef&scoped=true&"
import script from "./MyBookBuy.vue?vue&type=script&lang=js&"
export * from "./MyBookBuy.vue?vue&type=script&lang=js&"
import style0 from "./MyBookBuy.vue?vue&type=style&index=0&id=0babdcef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0babdcef",
  null
  
)

export default component.exports