<template>
<div class="text-center" v-if="isLoadData">
    <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
</div>
<div class="scopeComponent" v-else>
    <b-container class="bv-example-row">
        <b-row>
            <b-col sm="5" class="text-center">
                <div v-if="userInfoShow.profileImg != null && changeImg == false">
                    <p><img :src="userInfoShow.profileImg" class="profileImg"></p>
                    <p>
                        <b-button @click="changeImg = true" class="changeImgBtn" size="sm">เปลี่ยนรูปโปรไฟล์</b-button>
                    </p>
                    <p class="fontsize">{{userInfoShow.username}}</p>
                    <p class="fontsize">(#{{userInfoShow.member_id}})</p>
                </div>
                <div v-if="userInfoShow.profileImg == null || changeImg == true">
                    <vue-avatar :color="color" :width="150" :height="150" :rotation="parseFloat(rotation)" :borderRadius="parseFloat(200)" :scale="parseFloat(scale)" ref="vueavatar" @vue-avatar-editor:image-ready="onImageReady">
                    </vue-avatar>
                    <div v-if="$vssWidth >= 500">
                        <span style="fontSize:13px">Zoom : {{scale}}x</span><br>
                        <input type="range" min=1 max=3 step=0.02 v-model='scale' /><br>
                        <span style="fontSize:13px">Rotation : {{rotation}}°</span><br>
                        <input type="range" min=0 max=360 step=1 v-model='rotation' /><br>
                    </div>
                    <b-button class="saveImgBtn mr-2" v-on:click="saveClicked" size="sm">
                        <b-spinner v-if="spinSaveProfileImg" small label="Small Spinner" class="mr-2"></b-spinner>บันทึก
                    </b-button>
                    <b-button class="cancelImgBtn" @click="changeImg = false" size="sm">ยกเลิก</b-button>
                    <br>
                </div>
            </b-col>

            <b-col sm="7" class="inputInfo">
                <b-row style="marginBottom:8%">
                    <b-col sm="12">
                        <div class="myCoin">
                            <table style="width:100%">
                                <tr>
                                    <td class="fontsize">ทองทั้งหมด</td>
                                    <td><img src="./../../../assets/price.png" width="18px" /> <span class="fontsize">{{userInfoShow.gold}}</span></td>
                                </tr>
                            </table>
                        </div>
                    </b-col>
                </b-row>
                <label for="name">ชื่อในเว็บ</label>
                <b-form-input id="name" v-model="userInfoShow.displayname" placeholder="ชื่อในเว็บ" style="marginBottom:3%"></b-form-input>
                <label for="username">ชื่อผู้ใช้งาน</label>
                <b-form-input id="username" v-model="userInfoShow.username" placeholder="ชื่อผู้ใช้งาน" style="marginBottom:3%"></b-form-input>
                <label for="email">อีเมล์</label>
                <b-form-input id="email" v-model="userInfoShow.email" placeholder="อีเมล์" style="marginBottom:3%"></b-form-input>
                <label for="email">อนุญาตให้เว็บเก็บข้อมูลเพื่อปรับปรุง UI/UX</label>
                <br />
                <a-switch class="mb-2" :default-checked="(GET_ANALYTICS_INFO === 'true')? true : false" @change="privacyToggle" />
                <div class="saveChangeBtn" @click="updateProfile">
                    <b-spinner v-if="spinSaveProfileInfo" small label="Small Spinner" class="mr-2"></b-spinner>บันทึกข้อมูล
                </div>
                <div class="mt-3">
                    <label>เชื่อมต่อบัญชีกับ AppleID</label>
                    <div class="height-53px">
                        <vue-apple-signin v-show="!isAppleLink" type="continue" :color="isdarkmode? 'white':'black'"></vue-apple-signin>
                         <a-alert v-if="isAppleLink" message="บัญชีนี้มีการเชื่อมต่อกับ AppleID แล้ว" type="warning" />
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import axios from 'axios'
import {
    VueAvatar
} from 'vue-avatar-editor-improved'
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.$emit("emitModeToParent")
        }
    },
    mounted() {
        this.getProfile()
        this.$emit("emitModeToParent")
    },
    components: {
        VueAvatar: VueAvatar
    },
    data() {
        return {
            isLoadData: false,
            isdarkmode: localStorage.getItem('mode') == 'darkmode',
            spinSaveProfileImg: false,
            spinSaveProfileInfo: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            color: [238, 130, 109, 0],
            rotation: 0,
            scale: 1,
            changeImg: false,
            userInfoShow: {
                member_id: 0,
                profileImg: null,
                gold: 0,
                displayname: "",
                username: "",
                email: ""
            },
            userInfoKeep: {},
            no_avatar: require('@/assets/no-avartar.jpg'),
            isAppleLink: false
        }
    },

    methods: {
        ...mapActions(['SET_MEMBER_PROFILE_URL', 'SET_USER_INFO']),
        async saveClicked() {
            var img = this.$refs.vueavatar.getImage()
            if (img != "undefined") {
                this.spinSaveProfileImg = true
                var file = this.dataURLtoFile(img.toDataURL(), 'profileImg_' + this.userInfo.displayname)
                const formData = new FormData()
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                formData.append('avatar', file);
                try {
                    await axios
                        .post(
                            "https://api2.novelrealm.com/module/profile&action=avatar",
                            formData
                        )
                        .then(res => {
                            //console.log(res.data);
                            // if (res.data.status == 200) {
                            this.changeImg = false
                            this.userInfoShow.profileImg = res.data.member_profile_url
                            this.SET_MEMBER_PROFILE_URL(this.userInfoShow.profileImg)
                            this.spinSaveProfileImg = false
                            // }
                        });
                } catch (err) {
                    //console.log("FAILURE!!" + err);
                }
            } else {
                alert('please select profile image')
            }

        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename + "." + mime.substring(mime.indexOf("/") + 1), {
                type: mime
            });
        },
        onImageReady: function onImageReady() {
            this.scale = 1;
            this.rotation = 0;
        },
        async getProfile() {
            this.isLoadData = true
            const formData = new FormData()
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/profile&submodule=info",
                        formData
                    )
                    .then(res => {
                        //console.log(res);
                        if (res.status == 200) {
                            this.userInfoShow.member_id = res.data.member_id
                            this.userInfoShow.gold = res.data.member_gold
                            this.userInfoShow.displayname = res.data.member_displayname
                            this.userInfoShow.username = res.data.member_username
                            this.userInfoShow.email = res.data.member_email
                            this.userInfoKeep.displayname = res.data.member_displayname
                            this.userInfoKeep.username = res.data.member_username
                            this.userInfoKeep.email = res.data.member_email
                            if (res.data.member_profile_url != (null || "")) {
                                this.userInfoShow.profileImg = res.data.member_profile_url
                            } else {
                                this.userInfoShow.profileImg = this.no_avatar
                            }
                            this.isAppleLink = res.data.applelink
                            this.isLoadData = false
                        }
                    });
            } catch (err) {
                //console.log("FAILURE!!" + err);
            }
        },
        async updateProfile() {
            this.spinSaveProfileInfo = true
            const formData = new FormData()
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            if (this.userInfoKeep.displayname != this.userInfoShow.displayname) {
                formData.append('displayname', this.userInfoShow.displayname);
            }
            if (this.userInfoKeep.username != this.userInfoShow.username) {
                formData.append('username', this.userInfoShow.username);
            }
            if (this.userInfoKeep.email != this.userInfoShow.email) {
                formData.append('email', this.userInfoShow.email);
            }
            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/profile&action=updateinfo",
                        formData
                    )
                    .then(res => {
                        //console.log(res);
                        if (res.data.description != "no_change") {
                            this.SET_USER_INFO({
                                auth_token: this.userInfo.auth_token,
                                auth_memberid: this.userInfo.auth_memberid,
                                displayname: res.data.member_data.member_displayname,
                                member_gold: res.data.member_data.member_gold,
                                member_profile_url: res.data.member_data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                            this.userInfoShow.gold = res.data.member_data.member_gold
                            this.userInfoShow.displayname = res.data.member_data.member_displayname
                            this.userInfoShow.username = res.data.member_data.member_username
                            this.userInfoShow.email = res.data.member_data.member_email
                            this.userInfoKeep.displayname = res.data.member_data.member_displayname
                            this.userInfoKeep.username = res.data.member_data.member_username
                            this.userInfoKeep.email = res.data.member_data.member_email
                        }
                        this.spinSaveProfileInfo = false
                    });
            } catch (err) {
                //console.log("FAILURE!!" + err);
            }
        }
    }
};
</script>

<style scoped>
.saveImgBtn {
    background-color: #F5D087;
}

.saveImgBtn:hover {
    background-color: #FAC762;
}

.saveImgBtn,
.cancelImgBtn {
    border: none;
    border-radius: 5px;
}

.saveImgBtn:focus {
    background-color: #F5D087 !important;
}

.saveImgBtn:focus,
.cancelImgBtn:focus {
    box-shadow: none !important;
}

.changeImgBtn,
.addCoin,
.fontsize {
    font-size: 16px;
}

.changeImgBtn {
    background-color: #F5D087;
    border: none;
    border-radius: 6px;
    font-weight: bold;
}

.saveChangeBtn {
    background-color: #F5D087;
    border-radius: 6px;
    padding: 4px 0px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

.saveChangeBtn:hover,
.changeImgBtn:hover {
    background-color: #FAC762;
    cursor: pointer;
}

.scopeComponent {
    padding: 7.5% 0px;
}

.inputInfo {
    padding-right: 12%
}

.profileImg {
    border-radius: 200px;
    width: 200px;
    height: 200px;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}

label {
    font-size: 12px;
    margin-bottom: 0px;
}

input {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 8px;
}

@media screen and (max-width: 500px) {
    .inputInfo {
        padding-right: 6%;
    }

    .profileImg {
        width: 130px;
        height: 130px;
    }

    .changeImgBtn,
    .addCoin,
    .fontsize {
        font-size: 14px;
    }
}
.height-53px{
    height: 53px;
}
</style>
